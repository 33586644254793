import AddressFields from './components/address-fields';
import VButton from './components/button';
import VCheckbox from './components/checkbox';
import VInput from './components/input';
import VSelect from './components/select';
import { setValues } from './mixins/set-values';
import { stripeCard } from './mixins/stripe-card';

new Vue({
  el: '#page-content',
  components: { AddressFields, VButton, VCheckbox, VInput, VSelect },
  mixins: [ setValues, stripeCard ],
  data: {
    first_name: null,
    last_name: null,
    account: null,
    email: null,
    password: null,
    password_confirmation: null,
    company: null,
    industry_id: null,
    industries: [],
    address: null,
    address2: null,
    city: null,
    region: null,
    postal_code: null,
    country: null,
    phone: null,
    coupon: null,
    coupon_valid: null,
    coupon_message: '',
    auth_code: null,
    terms: false,
  },
  created() {
      if (typeof PlatformUser !== 'undefined') {
          this.setDataSource(PlatformUser);
          this.industries = Object.keys(PlatformUser.industries).map((key) => ({ value: key, name: _.startCase(PlatformUser.industries[key]) }));
      }
      
      this.loadData();
  },
  methods: {
    loadData() {
      this.first_name = this.setValue('first_name');
      this.last_name = this.setValue('last_name');
      this.account = this.setValue('account');
      this.email = this.setValue('email');
      this.company = this.setValue('company');
      this.industry_id = this.setValue('industry_id');
      this.address = this.setValue('address');
      this.address2 = this.setValue('address2');
      this.city = this.setValue('city');
      this.region = this.setValue('region');
      this.postal_code = this.setValue('postal_code');
      this.country = this.setValue('country');
      this.phone = this.setValue('phone');
    },
    validateCoupon: _.debounce(function() {
      this.coupon_valid = null;
      this.coupon_message = null;
                  
      axios.post('/users/info', { coupon: this.coupon })
        .then((response) => {
            this.coupon_valid = true;
            this.coupon_message = response.data.coupon + '!';
        })
        .catch(() => {
            this.coupon_valid = false;
            this.coupon_message = 'The promo code you entered is invalid';
        });
    }),
  }
});
