<template>
  <div>
    <div class="flex justify-between">
      <label
        v-if="label"
        :for="id"
        class="block text-sm font-medium leading-5 text-gray-700"
        :class="{ 'sr-only': srOnly }"
      >
        {{ label }}
      </label>
      <span v-if="optional" class="text-sm leading-5 text-gray-500">
        Optional
      </span>
    </div>

    <div class="mt-1 relative rounded-md shadow-sm">
      <select
        :id="id"
        :name="id"
        :value="value"
        v-bind="$attrs"
        :class="classes + ' ' + errorClasses"
        :disabled="disabled"
        :aria-disabled="disabled"
        :aria-invalid="hasError"
        :placeholder="srOnly ? label : false"
        @input="updateValue"
        v-on="listeners"
      >
        <option v-for="option in options" :id="option.value" :key="option.value" :value="option.value">
          {{ option.name }}
        </option>
      </select>

      <div v-if="hasError" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <i class="fas fa-exclamation-circle text-red-500" />
      </div>
    </div>

    <template v-if="hasError">
      <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600">
        {{ errorMessage(error) }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    classes: {
        type: String,
        default: 'block w-full py-2 px-3 border bg-white rounded-md focus:outline-none sm:text-sm disabled:bg-gray-50',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    id: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    optional: {
        type: Boolean,
        default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    srOnly: {
        type: Boolean,
        default: false,
    },
    validation: {
        type: Object,
        required: false,
    },
    value: {
        type: [String, Number, Boolean, Array, Object],
        default: null,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
    errorClasses() {
      if (this.hasError) {
        return 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';
      }

      return 'border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500';
    },
    errors() {
      return [];
    },
    hasError() {
      return false;
    },
  },
  methods: {
    updateValue(event) {
        const target = event.target;
        this.$emit('input', target.value);
    }
  },
};
</script>
