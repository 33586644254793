<template>
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-6">
    <div class="sm:col-span-6">
      <label for="address" class="block text-sm font-medium leading-5 text-gray-700">
        Address
      </label>

      <div class="mt-1 relative rounded-md shadow-sm">
        <google-autocomplete
          ref="address"
          id="address"
          name="address"
          classname="block w-full sm:text-sm rounded-md disabled:bg-gray-50 border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Street Address"
          @placechanged="getAddressData"
          @keyup="updateAddress"
        >
        </google-autocomplete>
      </div>
    </div>

    <div class="sm:col-span-6">
      <label for="address2" class="block text-sm font-medium leading-5 text-gray-700">
        Address Line 2
      </label>
      
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          placeholder="Address Line 2"
          name="address2"
          type="text"
          id="address2"
          class="block w-full sm:text-sm rounded-md disabled:bg-gray-50 border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
          v-model="address2"
          @keyup="$emit('update:address2', address2)"
        >
      </div>
    </div>

    <div class="sm:col-span-4">
      <label for="city" class="block text-sm font-medium leading-5 text-gray-700">
        City
      </label>
      
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          placeholder="City"
          name="city"
          type="text"
          id="city"
          class="block w-full sm:text-sm rounded-md disabled:bg-gray-50 border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
          :required="this.required"
          v-model="city"
          @keyup="$emit('update:city', city)"
        >
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="region" class="block text-sm font-medium leading-5 text-gray-700">
        State / Province / Region
      </label>
      
      <div class="mt-1 relative rounded-md shadow-sm">
        <region-select
          class="block w-full sm:text-sm rounded-md disabled:bg-gray-50 border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
          id="region"
          name="region"
          v-model="region"
          :country="country"
          :region="region"
        ></region-select>
      </div>
    </div>

    <div class="sm:col-span-3">
      <label for="postal_code" class="block text-sm font-medium leading-5 text-gray-700">
        Postal Code
      </label>
      
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          placeholder="Postal Code"
          name="postal_code"
          type="text"
          id="postal_code"
          class="block w-full sm:text-sm rounded-md disabled:bg-gray-50 border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
          :required="this.required"
          v-model="postal_code"
          @keyup="$emit('update:postal_code', postal_code)"
        >
      </div>
    </div>

    <div class="sm:col-span-3">
      <label for="country" class="block text-sm font-medium leading-5 text-gray-700">
        Country
      </label>
      
      <div class="mt-1 relative rounded-md shadow-sm">
        <country-select
          placeholder="Select Country"
          class="block w-full sm:text-sm rounded-md disabled:bg-gray-50 border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
          id="country"
          name="country"
          v-model="country"
          :country="country"
        ></country-select>
      </div>
    </div>
  </div>
</template>

<script>
    import countryRegionSelect from 'vue-country-region-select';
    import googleAutocomplete from 'vue-google-autocomplete';

    Vue.use(countryRegionSelect);

    export default {
        props: ['required'],
        components: {googleAutocomplete},
        data: function() {
            return {
                address: '',
                address2: '',
                city: '',
                region: '',
                postal_code: '',
                country: '',
            };
        },
        watch: {
            region: function() {
                this.$emit('update:region', this.region);
            },
            country: function() {
                this.$emit('update:country', this.country);
            },
        },
        mounted: function() {
            this.loadExistingData();
        },
        methods: {
            loadExistingData() {
                if (typeof PlatformLocation !== 'undefined') {
                    // Polyfill Object.entries()
                    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#polyfill
                    if (!Object.entries) {
                      Object.entries = function( obj ){
                        var ownProps = Object.keys( obj ),
                            i = ownProps.length,
                            resArray = new Array(i); // preallocate the Array
                        while (i--)
                          resArray[i] = [ownProps[i], obj[ownProps[i]]];

                        return resArray;
                      };
                    }

                    for (let [key, value] of Object.entries(PlatformLocation)) {
                        this[key] = value;
                    }
                }

                this.$refs.address.update(this.address);
            },
            updateAddress: function() {
                this.address = this.$refs.address.autocompleteText;
                this.$emit('update:address', this.address);
            },
            getAddressData: function(addressData, placeResultData) {
                // Get the short name for the country
                let address_components = placeResultData.address_components;
                for (let i = 0; i < address_components.length; i++) {
                    if (address_components[i].types[0] === 'country') {
                        this.country = address_components[i].short_name;
                    }
                }

                this.address = addressData.route;
                if (addressData.street_number) {
                    this.address = addressData.street_number + ' ' + this.address;
                }

                this.city = addressData.locality;
                this.postal_code = addressData.postal_code;

                this.$emit('update:country', this.country);
                this.$emit('update:address', this.address);
                this.$emit('update:city', this.city);
                this.$emit('update:postal_code', this.postal_code);

                Vue.nextTick(() => {
                    this.region = addressData.administrative_area_level_1;
                    this.$refs.address.update(this.address);
                    this.$emit('update:region', this.region);
                });
            },
        },
    };
</script>
