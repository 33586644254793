<template>
  <div>
    <div class="flex justify-between">
      <label
        v-if="label"
        :for="id"
        class="block text-sm font-medium leading-5 text-gray-700"
        :class="{ 'sr-only': srOnly }"
      >
        {{ label }}
      </label>
      <span v-if="optional" class="text-sm leading-5 text-gray-500">
        Optional
      </span>
    </div>

    <div class="mt-1 relative rounded-md shadow-sm" :class="{ flex: !!$slots.action }">
      <div v-if="leadingAddOn || !!$slots.leadingAddOn" class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span class="text-gray-500 sm:text-sm sm:leading-5">
          <span v-if="leadingAddOn" v-text="leadingAddOn"></span>
          <slot v-else name="leadingAddOn"></slot>
        </span>
      </div>
      <div :class="{ 'relative flex items-stretch grow focus-within:z-10': !!$slots.action }">
        <input
          ref="input"
          :id="id"
          :name="id"
          :type="type"
          :value="value"
          v-bind="$attrs"
          :class="inputClasses + ' ' + errorClasses"
          :disabled="disabled"
          :aria-disabled="disabled"
          :aria-invalid="hasError"
          :placeholder="srOnly ? label : placeholder"
          @input="updateValue"
          v-on="listeners"
        />
      </div>
      <slot name="action"></slot>
      <div
        v-if="(trailingAddOn || !!$slots.trailingAddOn) && !hasError"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <span class="text-gray-500 sm:text-sm sm:leading-5">
          <span v-if="trailingAddOn" v-text="trailingAddOn"></span>
          <slot v-else name="trailingAddOn"></slot>
        </span>
      </div>

      <div v-if="hasError" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <i class="fas fa-exclamation-circle text-red-500" />
      </div>
    </div>

    <template v-if="hasError">
      <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600">
        {{ error }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    classes: {
        type: String,
        default: 'block w-full py-2 px-3 border bg-white rounded-md focus:outline-none sm:text-sm disabled:bg-gray-50',
    },
    leadingAddOn: {
      type: String,
      default: null,
    },
    leadingAddOnWidth: {
      type: String,
      default: 'pl-7',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    id: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    optional: {
        type: Boolean,
        default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    srOnly: {
        type: Boolean,
        default: false,
    },
    trailingAddOn: {
      type: String,
      default: null,
    },
    trailingAddOnWidth: {
      type: String,
      default: 'pr-12',
    },
    type: {
        type: String,
        default: 'text',
    },
    validation: {
        type: Object,
        required: false,
    },
    value: {
        type: [String, Number, Boolean, Array, Object],
        default: null,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
    inputClasses() {
      let inputClasses = this.classes;
      
      if (this.leadingAddOn) {
        inputClasses += ' ' + this.leadingAddOnWidth;
      }

      if (this.trailingAddOn) {
        inputClasses += ' ' + this.trailingAddOnWidth;
      }
      
      if (!!this.$slots.action) {
        inputClasses = inputClasses.replace('rounded-md', 'rounded-l-md');
      }

      return inputClasses;
    },
    errorClasses() {
      if (this.hasError) {
        return 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';
      }

      return 'border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500';
    },
    errors() {
      return [];
    },
    hasError() {
      return false;
    },
  },
  methods: {
    updateValue(event) {
        const target = event.target;
        this.$emit('input', target.value);
    }
  },
};
</script>
