<template>
  <component :is="link !== null ? 'a' : 'button'" v-bind="$attrs" :class="[baseClasses, colorClasses]" :disabled="loading || disabled" v-on="$listeners" :href="link">
    <span v-if="loading">
      <i class="fas fa-spinner-third fa-spin mr-2 h-5 w-5"></i>
      <slot name="loading">Processing...</slot>
    </span>
    <slot v-else />
  </component>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: 'inline-flex items-center justify-center rounded-md shadow-sm border px-4 py-2 border text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
    },
    color: {
      type: String,
      default: 'blue',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    baseClasses() {
      let classes = this.classes;
      
      if (this.fullWidth) {
        classes += ' w-full ';
      }

      if (this.disabled || this.loading) {
        classes += ' cursor-not-allowed ';
      }

      return classes;
    },

    colorClasses() {
      if (this.color === 'transparent') {
          return;
      }
      
      if (this.loading || this.disabled) {
        return this.disabledColorClasses;
      }
      
      if (this.color === 'red') {
          return 'bg-red-600 hover:bg-red-500 focus:ring-red-500 text-white border-transparent';
      }

      if (this.color === 'yellow') {
          return 'bg-yellow-200 hover:bg-yellow-100 focus:ring-yellow-300 text-yellow-800 border-transparent';
      }

      if (this.color === 'green') {
          return 'bg-green-600 hover:bg-green-500 focus:ring-green-500 text-white border-transparent';
      }

      if (this.color === 'white') {
          return 'border-gray-300 bg-white text-gray-700 hover:text-gray-500 focus:ring-blue-500';
      }

      if (this.color === 'gray') {
          return 'border-gray-300 bg-white text-gray-400 hover:text-gray-500 focus:ring-blue-500 border-transparent';
      }

      return 'bg-blue-600 hover:bg-blue-500 focus:ring-blue-500 text-white border-transparent';
    },

    disabledColorClasses() {
      if (this.color === 'red') {
        return 'bg-red-400 hover:bg-red-300 focus:ring-red-500 text-white border-transparent';
      }
      
      if (this.color === 'yellow') {
        return 'bg-yellow-100 hover:bg-yellow-50 focus:ring-yellow-200 text-yellow-800 border-transparent';
      }
      
      if (this.color === 'green') {
        return 'bg-green-400 hover:bg-green-300 focus:ring-green-500 text-white border-transparent';
      }
  
      if (this.color === 'white' || this.color === 'gray') {
        return 'border-gray-300 bg-white text-gray-300 hover:text-gray-500 focus:ring-blue-300';
      }
             
      return 'bg-blue-400 hover:bg-blue-300 focus:ring-blue-500 text-white border-transparent';
    },
  },
};
</script>
