export const stripeCard = {
  data: {
    stripe: null,
    cardElement: null,
    loading: false,
    payment_method: null,
    skip_setup: false,
    form_validate: window.StripeFormValidate ?? {},
    first_name: null,
    last_name: null,
    address: null,
    address2: null,
    city: null,
    region: null,
    postal_code: null,
    country: null,
  },
  computed: {
    name() {
      let first_name = this.first_name;
      let last_name = this.last_name;
      
      if (first_name === null) {
          first_name = '';
      }
      
      if (last_name === null) {
          last_name = '';
      }
  
      return (first_name + ' ' + last_name).trim();
    },
  },
  created() {
    if (typeof StripeSkipSetup !== 'undefined') {
        this.skip_setup = StripeSkipSetup;
    }
  },
  mounted() {
      this.stripe = window.Stripe(document.querySelector('meta[name="stripe-key"]').getAttribute('content'));
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {
          hidePostalCode: true,
          style: {
            base: {
              color: '#555555',
              fontSize: '14px',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSmoothing: 'antialiased',
            },
          },
          classes: {
            base: 'appearance-none block w-full py-2 px-3 border bg-white rounded-md focus:outline-none sm:text-sm disabled:bg-gray-50 rounded-md shadow-sm border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500',
            invalid: 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500',
          },
      });
      this.cardElement.mount('#card-element');
  },
  methods: {
    setupCard(clientSecret, skipCardSetup = false) {
        this.loading = true;
        var params = {
          payment_method_data: {
            billing_details: {
              name: this.name,
              address: {
                line1: this.address,
                line2: this.address2,
                city: this.city,
                state: this.region,
                postal_code: this.postal_code,
                country: this.country,
              },
            },
          },
        };

        if (this.payment_method !== null || this.skip_setup) {
          // Payment Method has already been validated and setup.
          return this.validateAndSubmit();
        }

        this.stripe.handleCardSetup(clientSecret, this.cardElement, params).then(function(result) {
          if (result.error) {
            this.loading = false;
  
            return window.notificationHandler.showErrors([result.error.message]);
          }

          this.payment_method = result.setupIntent.payment_method;
  
          this.validateAndSubmit();
        }.bind(this));
    },
    skipSetup(skip_setup) {
      this.skip_setup = skip_setup;
    },
    validateAndSubmit() {
      if (this.isValid()) {
        Vue.nextTick(() => {
          this.$refs.stripeForm.submit();
        });
      }
    },
    isValid() {
      for (let key in this.form_validate) {
        if (this.form_validate.hasOwnProperty(key) && !this[key]) {
          this.loading = false;
          window.notificationHandler.showErrors([this.form_validate[key].error]);

          return false;
        }
      }
  
      return true;
    },
  },
};
