<template>
  <div>
    <div class="relative flex items-start">
      <div class="absolute flex items-center h-5">
        <input
          :id="id"
          :name="name !== null ? name : id"
          type="checkbox"
          v-bind="$attrs"
          class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
          :value="value"
          :disabled="disabled"
          :aria-disabled="disabled"
          :checked="checked"
          @change="$emit('change', $event.target.checked)"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label :for="id" class="font-medium text-gray-700">
          <slot></slot>
        </label>
        <p class="text-gray-500">
          <slot name="subtitle"></slot>
        </p>
      </div>
    </div>

    <template v-if="hasError">
      <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600">
        {{ error }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change',
  },
  
  props: {
    checked: {
      type: [Array, Boolean, Number],
      default: false,
    },
    classes: {
        type: String,
        default: 'block w-full py-2 px-3 border bg-white rounded-md focus:outline-none sm:text-sm disabled:bg-gray-50',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    id: {
        type: String,
        required: true,
    },
    name: {
      type: String,
      default: null,
    },
    optional: {
        type: Boolean,
        default: false,
    },
    srOnly: {
        type: Boolean,
        default: false,
    },
    validation: {
        type: Object,
        required: false,
    },
    value: {
        type: [String, Number, Boolean, Array, Object],
        default: null,
    },
  },
  computed: {
    errorClasses() {
      if (this.hasError) {
        return 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';
      }

      return 'border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500';
    },
    errors() {
      return [];
    },
    hasError() {
      return false;
    },
  },
};
</script>
