export const setValues = {
  data() {
    return {
        oldData: {},
        source: {},
    };
  },
  mounted: function() {
    if (typeof PlatformOldData !== 'undefined') {
      this.oldData = PlatformOldData;
      this.loadData();
    }
  },
  methods: {
    setValue(string, alternateSource) {
      if (this.oldData.hasOwnProperty(string)) {
        return this.oldData[string];
      }
      
      if (typeof alternateSource !== 'undefined' && alternateSource.hasOwnProperty(string)) {
        return alternateSource[string];
      }
      
      if (typeof this.source !== 'undefined' && this.source.hasOwnProperty(string)) {
        return this.source[string];
      }
      
      return this[string];
    },
    setDataSource(source) {
      this.source = source;
    },
    loadData() {
      // Override this with values to be set in parent.
    },
  },
}
